<template>
  <div class="d-flex row justify-content-center">
    <Header class="d-flex col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3"/>
  </div>
  <div class="d-flex row justify-content-center content-body">
    <LoadingOverlay v-if="this.isLoading"/>
    <router-view class="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3"></router-view>
  </div>
  <div class="d-flex row justify-content-center">
    <Footer class="d-flex col col-12 col-sm-9 col-md-7 col-lg-6 col-xl-4"/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import LoadingOverlay from "@/components/LoadingOverlay";
import {mapState, mapMutations} from "vuex";

export default {
  components: {Header, Footer, LoadingOverlay},
  computed: {
    ...mapState(['isLoading'])
  },
  methods: {
    ...mapMutations(['setIsLoading'])
  },
};
</script>

<style>
Header {
  position: fixed;
  background-color: #ffffff;
  height: 50px;
  top: 0;
  z-index: 1;
  width: 100%;
}

.content-body {
  position: absolute;
  height: auto;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 0;
}

Footer {
  position: fixed;
  bottom: 0;
  border-radius: 15px 15px 0 0;
  background-color: #000000;
  height: 75px;
  color: white;
  font-size: 12px;
  z-index: 1;
}
</style>
