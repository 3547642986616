import store from "@/store";
import UserDataService from "@/services/userDataService";
import GameDataService from "@/services/gameDataService";

export async function userAuthenticate(to, from, next) {
    if (store.state.user) return next();

    store.commit('setIsLoading', true);
    const urlParams = new URLSearchParams(window.location.search);
    const localStorageToken = localStorage.getItem('token');
    const fantasyToken = urlParams.get('fantasyToken') ? urlParams.get('fantasyToken') : localStorageToken ? localStorageToken : undefined;

    if (fantasyToken) {
        await fetchUser(fantasyToken);
        store.commit('setIsLoading', false);
        return next();
    }
}

async function setUserData(msisdn) {
    const stats = await GameDataService.getUserLandingPageStats(`${msisdn}`);
    store.commit('setUserLandingStats', stats.data);
}

async function fetchUser(token) {
    localStorage.setItem('token', token);
    const user = await UserDataService.getUser(`${token}`);
    await setUserData(user.data.msisdn);
    await UserDataService.trackLogin(user.data.msisdn)
    store.commit('setUser', user.data);
}
