<template>
  <footer class="d-flex flex-column justify-content-center p-3">
    <div class="d-flex justify-content-evenly mt-4">
      <div @click="routeToPage('landing')" class="d-flex flex-column text-center footer-navbar-img col-5">
        <div class="profile-container">
          <div v-if="!isIconActive(this.$route, 'LandingPage')"><img src="../assets/game/profile-nav-icon.png"></div>
          <div v-if="isIconActive(this.$route, 'LandingPage')"><img :src="require(`../assets/game/profile-nav-icon-active.png`)"></div>
          <div :class="isIconActive(this.$route, 'LandingPage') ? 'footer-selected-text' : 'footer-default-text'">Profile</div>
        </div>
      </div>
      <div @click="playClicked()" class="d-flex flex-column text-center play-icon-container">
        <div class="half-circle"></div>
        <div v-if="!isPlayIconActive(this.$route)"><img class="play-icon" src="../assets/game/play-nav-icon.png"></div>
        <div v-if="isPlayIconActive(this.$route)"><img class="play-icon" :src="require(`../assets/game/play-nav-icon-active.png`)"></div>
        <div :class="isPlayIconActive(this.$route) ? 'pt-2 footer-selected-text' : 'pt-2 footer-default-text'">Play</div>
      </div>
      <div @click="routeToPage('my-results/1')" class="d-flex flex-column text-center footer-navbar-img col-5">
        <div class="results-container">
          <div v-if="!isIconActive(this.$route, 'MyResults')"><img src="../assets/game/results-nav-icon.png"></div>
          <div v-if="isIconActive(this.$route, 'MyResults')"><img :src="require(`../assets/game/results-nav-icon-active.png`)"></div>
          <div :class="isIconActive(this.$route, 'MyResults') ? 'footer-selected-text' : 'footer-default-text'">Results</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import GameDataService from "@/services/gameDataService";

export default {
  name: "Footer",
  computed: {
    ...mapState(['userLandingStats', 'user']),
  },
  methods: {
    ...mapMutations(['setUserLandingStats', 'setSelectedSportLeague']),
    isIconActive(route, iconName) {
      if (route.name === iconName) return true
    },
    isPlayIconActive(route) {
      const validRouteNames = ['NoRoundsPage', 'MakePredictionsSoccer', 'MakePredictionsRugby'];
      return validRouteNames.includes(route.name);
    },
    routeToPage(pageRoute) {
      this.$router.push(`/${pageRoute}`)
    },
    async playClicked() {
      const getActiveSportsResponse = await GameDataService.getActiveSports(this.user.msisdn, this.user.username)
      this.filterPromoRound(getActiveSportsResponse.data);
    },
    filterPromoRound(upcomingRound) {
      const filteredFreeRound = upcomingRound.find(sport => sport.Free === true);

      if (filteredFreeRound) {
        this.sportType = filteredFreeRound.SportName;
        this.promoRoundId = filteredFreeRound.RoundID;
        this.setSelectedSportLeague(filteredFreeRound.LeagueAbbreviation);
        this.$router.push({name: `MakePredictions${this.sportType}`, params: {roundId: this.promoRoundId}});
      } else {
        this.$router.push({name: `NoRoundsPage`});
      }
    }
  },
}
</script>

<style scoped>

.play-icon {
  width: 65px;
  height: 65px;
}

.play-icon-container {
  z-index: 1;
  position: relative;
  bottom: 25px;
}

.half-circle {
  background-color: #000000;
  position: absolute;
  z-index: -1;
  width: 90px;
  height: 91px;
  border-radius: 45px 45px 0 0;
  left: -18%;
  top: -14%;
}

.my-rounds-nav-icon-img-container img {
  width: 30px;
  height: 30px;
}

.footer-navbar-img {
  position: relative;
  margin-top: 10px;
}

.footer-navbar-img img, .footer-navbar-rounds-img img {
  position: relative;
  width: 30px;
  height: 30px;
}

.profile-container {
  right: 20px;
}

.results-container {
  left: 20px;
}

.results-container, .profile-container {
  position: relative;
}

.footer-selected-text {
  color: #bfa668;;
}

.footer-default-text {
  color: #ffffff;
}

</style>

