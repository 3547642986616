<template>
  <div class="d-flex flex-row justify-content-center pt-4 header-container">
    <img class="fantasy-header-icon" src="../assets/game/main-fantasy-header.png">
    <img @click="routeToPage()" class="settings-gear-icon" src="../assets/game/settings-gear-wheel.png">
  </div>
</template>

<script>

export default {
  name: "Header",
  methods: {
    routeToPage() {
      this.$router.push({name: `UserSettings`});
    },
  },
}
</script>

<style scoped>
.header-container {
  position: relative;
}

.fantasy-header-icon {
  position: absolute;
  left: 10px;
  top: 15px;
  width: 250px;
}

.settings-gear-icon {
  position: absolute;
  height: 28px;
  right: 20px;
  top: 18px;
}
</style>
