import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
    predictorService: `${process.env.VUE_APP_PREDICTOR_SERVICE_LIVE}`,
    upstreamService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    vodaPayIntegrationHost: `${process.env.VUE_APP_VODAPAY_INTEGRATION_HOST}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
    entryPortalHost: `${process.env.VUE_APP_ENTRY_PORTAL}`,
    predictorHost: `${process.env.VUE_APP_PREDICTOR_HOST}`,
    termsConditionsHost: `${process.env.VUE_APP_TERMS_CONDITIONS_HOST}`,
    vodacomIntegrationService: `${process.env.VUE_APP_VODACOM_INTEGRATION_SERVICE}`,
}

export const stagingConfig = {
    predictorService: `${process.env.VUE_APP_STAGING_PREDICTOR_SERVICE}`,
    upstreamService: `${process.env.VUE_APP_STAGING_UPSTREAM_SERVICE}`,
    vodaPayIntegrationHost: `${process.env.VUE_APP_VODAPAY_INTEGRATION_HOST}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
    entryPortalHost: `${process.env.VUE_APP_ENTRY_PORTAL}`,
    predictorHost: `${process.env.VUE_APP_PREDICTOR_HOST}`,
    termsConditionsHost: `${process.env.VUE_APP_TERMS_CONDITIONS_HOST}`,
    vodacomIntegrationService: `${process.env.VUE_APP_VODACOM_INTEGRATION_SERVICE}`,
}

const environment = getEnvironment()

export const environmentConfig = getEnvObject();


function getEnvironment() {
    let base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'production'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return productionConfig
    if (environment === 'production') return productionConfig
}
