import {createRouter, createWebHistory} from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";

const routes = [
    {
        path: '/',
        redirect: 'landing'
    },
    {
        path: '/landing',
        name: 'LandingPage',
        component: () => import("@/views/LandingPage"),
    },
    {
        path: '/how-to-play',
        name: 'HowToPlay',
        component: () => import("@/views/HowToPlay"),
    },
    {
        path: '/make-predictions-soccer/:roundId',
        name: 'MakePredictionsSoccer',
        component: () => import("@/views/MakePredictionsSoccer"),
    },
    {
        path: '/make-predictions-rugby/:roundId',
        name: 'MakePredictionsRugby',
        component: () => import("@/views/MakePredictionsRugby"),
    },
    {
        path: '/no-rounds-predict',
        name: 'NoRoundsPage',
        component: () => import("@/views/NoRoundsPage"),
    },
    {
        path: '/my-results/:sportId',
        name: 'MyResults',
        component: () => import("@/views/MyResults"),
    },
    {
        path: '/settings',
        name: 'UserSettings',
        component: () => import("@/views/UserSettings"),
        children: [
            {
                path: 'username',
                name: 'EditUsername',
                component: () => import("@/components/EditUsername"),
            },
            {
                path: 'notifications',
                name: 'ManageNotifications',
                component: () => import("@/components/ManageNotifications"),
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next)
})

export default router
