import {createStore} from 'vuex'

export default createStore({
    state: {
        isLoading: false,
        user: undefined,
        userLandingStats: undefined,
        roundEntered: undefined,
        subscriptionStatus: undefined,
        earnPointsPopup: false,
        rugbyInsightsPopup: false,
        selectedSportLeague: undefined,
        soccerInsightsPopup: undefined,
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading;
        },
        setUser(state, data) {
            state.user = data;
        },
        setUserLandingStats(state, userLandingStats) {
            state.userLandingStats = userLandingStats;
        },
        setRoundEntered(state, roundEntered) {
            state.roundEntered = roundEntered;
        },
        setUserSubscriptionStatus(state, subscriptionStatus) {
            state.subscriptionStatus = subscriptionStatus;
        },
        setEarnPointsPopup(state, earnPointsPopup) {
            state.earnPointsPopup = earnPointsPopup;
        },
        setRugbyInsightsPopup(state, rugbyInsightsPopup) {
            state.rugbyInsightsPopup = rugbyInsightsPopup
        },
        setSelectedSportLeague(state, selectedSportLeague) {
            state.selectedSportLeague = selectedSportLeague
        },
        setSoccerInsightsPopup(state, soccerInsightsPopup) {
            state.soccerInsightsPopup = soccerInsightsPopup
        },
    },
    actions: {},
    modules: {}
})
