import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class UserDataService {

    static async vodapayUserAuth(userId) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.vodaPayIntegrationHost}/vodapay-login`,
            data: {
                vodapayUserId: userId,
            }
        })
    }

    static async trackLogin(msisdn) {
        return await axios.post(`${environmentConfig.upstreamService}/track-login`, {
            msisdn: msisdn,
            revenuePartnerId: 1,
            isFantasy: 0,
            isScorePredictor: 0,
            isWeb: 1,
            isFreePlay: 1,
        })
    }


    static async validateUsernameAgainstDB(username, userToken) {
        return axios.post(`${environmentConfig.upstreamService}/user-authentication/validate-username`, {
            username: username,
            userToken: userToken
        })
    }

    static async updateUserData(msisdn, newUsername, serviceMessages) {
        return axios.get(`${environmentConfig.upstreamService}/update-user-profile/?msisdn=${msisdn}&username=${newUsername}&optInServiceMsgs=${serviceMessages}`)
    }

    static async getUserStatus(token) {
        return await UserDataService.invokeGetUserStatus(token)
    }

    static async invokeGetUserStatus(token) {
        return await axios.post(`${environmentConfig.upstreamService}/get-user-status`, {
            token
        })
    }

    static async getUser(token) {
        return await axios.post(`${environmentConfig.upstreamService}/get-user-status`, {
            token
        })
    }

    static async subscribeV2(msisdn, sessionId) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.upstreamService}/v2/subscription`,
            data: {
                msisdn: msisdn,
                revenuePartnerId: '1',
                action: 'Subscription',
                sessionId: sessionId
            }
        })
    }
}
